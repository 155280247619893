* {
  outline: none;
  -webkit-tap-highlight-color: #0000 !important;
  tap-highlight-color: #0000 !important;
}

html, body, div, form, p, ul, li, span, label, img {
  outline: none;
  margin: 0;
  padding: 0;
}

html, body {
  width: 100%;
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  -webkit-user-select: none;
  user-select: none;
  position: relative;
  overflow: hidden;
}

.app-page {
  width: 100%;
  height: 100%;
  display: none;
  position: relative;
  overflow: hidden;
}

.app-loaded .app-page {
  display: block;
}

.app-block-touch:before {
  content: "";
  z-index: 99000;
  background: none;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.app-clickblocker {
  z-index: 9000;
  opacity: 0;
  background: #fff;
  position: fixed;
  inset: 0;
}

[data-target], .cursor-pointer {
  cursor: pointer !important;
}

.app-button {
  text-transform: uppercase;
  box-sizing: border-box;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: .75rem;
  font-size: .75rem;
  font-weight: 700;
  text-decoration: none;
  display: flex;
}

.app-fields {
  overflow: hidden;
}

.app-field {
  background: #fff;
  position: relative;
}

.app-field.required:after {
  color: red;
  content: "*";
  font-size: 16px;
  line-height: 1;
  position: absolute;
  top: 10px;
  right: 10px;
}

.app-field.size-2, .app-field.size-3, .app-field.size-4, .app-field.size-5, .app-field.size-6, .app-field.size-7, .app-field.size-8, .app-field.size-9, .app-field.size-10 {
  float: left;
  vertical-align: top;
}

.app-field.size-12 {
  clear: both;
  width: 100%;
}

.app-field.size-10 {
  width: 83.3333%;
}

.app-field.size-9 {
  width: 75%;
}

.app-field.size-8 {
  width: 66.6667%;
}

.app-field.size-7 {
  width: 58.3333%;
}

.app-field.size-6 {
  width: 50%;
}

.app-field.size-5 {
  width: 41.6667%;
}

.app-field.size-4 {
  width: 33.3333%;
}

.app-field.size-3 {
  width: 25%;
}

.app-field.size-2 {
  width: 16.6667%;
}

@media (width >= 576px) {
  .app-field.size-12 {
    float: left;
    clear: none;
  }

  .app-field.size-md-12 {
    float: none;
    clear: both;
    width: 100%;
  }

  .app-field.size-md-10 {
    width: 83.3333%;
  }

  .app-field.size-md-9 {
    width: 75%;
  }

  .app-field.size-md-8 {
    width: 66.6667%;
  }

  .app-field.size-md-7 {
    width: 58.3333%;
  }

  .app-field.size-md-6 {
    width: 50%;
  }

  .app-field.size-md-5 {
    width: 41.6667%;
  }

  .app-field.size-md-4 {
    width: 33.3333%;
  }

  .app-field.size-md-3 {
    width: 25%;
  }

  .app-field.size-md-2 {
    width: 16.6667%;
  }
}

.app-field > label {
  z-index: 1;
  text-transform: uppercase;
  margin: 0;
  padding: 10px 20px 5px;
  font-size: 10px;
  font-weight: 900;
  display: block;
}

.app-field > .app-input {
  box-sizing: border-box;
  -webkit-appearance: none;
  background: #fff;
  border: 0;
  border-bottom: 1px solid #f2f2f2;
  border-radius: 0;
  width: 100%;
  height: 31px;
  padding: 0 20px 10px;
  font-size: 14px;
}

.app-field > .app-input:focus {
  border-color: #00f;
}

.app-field > .app-input.error {
  border-color: red;
}

.app-fields > .app-field.field-type-button:last-child, .app-fields > .app-field.field-type-submit:last-child {
  margin-top: -2px;
}

.app-field textarea.app-input {
  resize: none;
  height: 100px;
}

.app-field .app-input-search {
  -webkit-background-size: 16px 16px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAACjElEQVR42r3Wz2sTQRjG8e2maW1rTYIoaPEgHqz/gD+KFi8VRb0pVPTi2UPNQRRCEHpQkCKIp0ilQg/BVqQKUi8q1lRzERQ0x3pQVKJIxTaxpql+DzkMD+xkNo1d+NBudt5535md3VnPdmQyGfP/FrSjC+trOhDxmnxo4k70I4lxzOA18riPYRzFFolvSvJBPMU8/los4w3S8JtRRCfGUZJEK3KuKiigV4pwHzl/43gYkLSKMhZRQsVsZ7T9jN1ShFPyCCahHVbwEiM4gyM4gRQe4Tu04DnskCLqFjACTf4Vg4gHxPo4gCfQ2HyY0e/BvIziIzY7LtgIpqC37YLrLIxJ8AK2SbBLIW+ln29ordfBdryXFZ2Eh7CLuBdVo4gyTmuhGjyA30byOexq5B2CKLIyC7fqBZ6V0U8jagkJ299jtHmWVXxJAsZq1xot4DD+GP3NYqOtgJQUkFllAQMoG/3l9WnSXS4p9yy7ys3suAzoOWK2gJMS8AKJBpP7uCj9PagXtBdFI+AH+mvXwhbQjbzslFfqPYYJ5OQ23IEPD2HeA6dkXyihz6WDFJblNTpkPN+BiWHO5E8ZyCvXytfhiwRXkZa2Qf0cRBG6F+yXWGsRhwL292nsRBei8NGKDmzCZePbQL1DPMw9TGJFR1IzgxtI4yqm8Av6LaDnOfSEmYlzWNKZCKJtAtrnEJcirNvqPnwK6tTyyfbM+F2vF9DmOhPmLfmABSzJVlvBIooYRQIeJiyFFlzXhJ73YQjXMYqbSOEYuqXtBtyTxLomtjrv8a7taswiJiy3bxYxr8mHLmYfWcua6JHQ5hcCP2BN3Eb7/8yva2LSSH4XsbVKbm5413AeCePamhYRRYv52z/AKK4/BRObOQAAAABJRU5ErkJggg==");
  background-position: 10px;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  height: 40px;
  margin: 0;
  padding: 10px 5px 10px 36px;
}

.app-page, .app-content {
  z-index: 2000;
}

.app-content {
  z-index: 2000;
  box-sizing: border-box;
  -webkit-user-select: text;
  user-select: text;
  position: relative;
  overflow: auto;
}

.app-list {
  list-style: none;
}

.app-nav {
  list-style: none;
  display: flex;
  position: relative;
}

.app-nav > li, .app-nav > .app-button {
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: .75rem;
  transition: all .3s;
  display: flex;
}

.app-bottom-nav {
  text-align: center;
  z-index: 5000;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.app-bottom-nav > .app-nav {
  height: 100%;
}

.app-toast {
  opacity: 0;
  z-index: 5025;
  color: #fff;
  cursor: pointer;
  padding: 20px;
  position: absolute;
  bottom: -50px;
  left: 0;
  right: 0;
}

.app-toast.success {
  background: #3c7;
}

.app-toast.danger {
  background: #dd4539;
}

.app-toast.warning {
  background: #ec1;
}

.app-toast.info {
  background: #4486f0;
}

.app-dialog-container {
  z-index: 5005;
  background: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: background .425s cubic-bezier(.4, .6, .05, 1);
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.app-dialog-container.enabled {
  background: #000c;
}

.app-dialog-container.enabled .app-dialog {
  transform: translate3d(0, 0, 0) !important;
}

.app-dialog {
  width: 80%;
  max-width: 300px;
  transition: transform .425s cubic-bezier(.4, .6, .05, 1);
}

.app-collapse .app-section {
  display: none;
}

.app-collapse.open .app-section {
  display: block;
}

.app-swipes {
  border-top: 1px solid #f2f2f2;
  height: 40px;
  position: relative;
  background: linear-gradient(to right, #2b9 0% 50%, #d45113 50% 100%) !important;
}

.app-swipes:first-child {
  border-top: 0;
}

.app-swipes .app-section {
  z-index: 50;
  margin: 0;
  padding: 0;
}

.app-swipes.checked .app-section {
  color: #2b9;
  margin-left: 5px;
}

.app-swipes.unchecked .app-section {
  color: #d45113;
  margin-right: 5px;
}

.app-swipes:before, .app-swipes:after {
  box-sizing: border-box;
  color: #fff;
  width: 45%;
  height: 40px;
  padding: 0 10px;
  line-height: 40px;
  position: absolute;
  top: 0;
}

.app-swipes:before {
  content: "←";
  text-align: left;
  left: 0;
}

.app-swipes:after {
  content: "→";
  text-align: right;
  right: 0;
}

.app-loader {
  z-index: 5050;
  background: #000;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.app-loader:after {
  content: "";
}
/*# sourceMappingURL=index.6e29b6cd.css.map */
