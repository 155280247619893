/* Reset */
*{ -webkit-tap-highlight-color:rgba(0,0,0,0)!important; tap-highlight-color:rgba(0,0,0,0)!important; outline: none;}
html,body,div,form,p,ul,li,span,label,img{ margin:0; padding:0; outline:none }

html, body{ height:100%; width:100% }
body{position:relative;-webkit-font-smoothing:antialiased; -webkit-text-size-adjust: none; user-select: none;overflow:hidden;}

.app-page{ display:none; position:relative; height:100%; width:100%; overflow:hidden}
.app-loaded .app-page{ display:block }

/* Utilities */
.app-block-touch:before{ content: ''; position: fixed; top:0; left:0; right:0; bottom:0; overflow: hidden; background: transparent; z-index: 99000; }
.app-clickblocker{z-index:9000;position:fixed;inset:0;background:#fff;opacity:0}

/* Buttons */
[data-target], .cursor-pointer { cursor: pointer !important; }
.app-button{ padding: 0.75rem; font-weight: 700; text-decoration: none; text-transform: uppercase; font-size: 0.75rem; display: flex; justify-content: center; align-items: center; box-sizing: border-box; cursor: pointer; }

/* Inputs */
.app-fields{ overflow: hidden;}
.app-field{ position: relative; background: #fff;}
.app-field.required:after{color: red; content: "*"; font-size: 16px; position: absolute; right: 10px; top: 10px; line-height: 1;}

.app-field.size-2, .app-field.size-3, .app-field.size-4, .app-field.size-5, 
.app-field.size-6, .app-field.size-7, .app-field.size-8, .app-field.size-9, 
.app-field.size-10{ float: left; vertical-align: top; }

.app-field.size-12{ width: 100%; clear: both;}
.app-field.size-10{ width: 83.3333333%; }
.app-field.size-9{ width: 74.99999997%; }
.app-field.size-8{ width: 66.66666664%; }
.app-field.size-7{ width: 58.33333331%; }
.app-field.size-6{ width: 50%; }
.app-field.size-5{ width: 41.66666665%; }
.app-field.size-4{ width: 33.3333333%; }
.app-field.size-3{ width: 24.99999999%; }
.app-field.size-2{ width: 16.66666666%; }

/* Small */
@media (width >= 576px) {
    .app-field.size-12{ float: left; clear: none;}
    .app-field.size-md-12{ width: 100%; float: none; clear: both; }
    .app-field.size-md-10{ width: 83.3333333%; }
    .app-field.size-md-9{ width: 74.99999997%; }
    .app-field.size-md-8{ width: 66.66666664%; }
    .app-field.size-md-7{ width: 58.33333331%; }
    .app-field.size-md-6{ width: 50%; }
    .app-field.size-md-5{ width: 41.66666665%; }
    .app-field.size-md-4{ width: 33.3333333%; }
    .app-field.size-md-3{ width: 24.99999999%; }
    .app-field.size-md-2{ width: 16.66666666%; }
}


.app-field > label{ font-size: 10px; padding: 0; margin: 0; display: block; padding: 10px 20px 5px; font-weight: 900; z-index: 1; text-transform: uppercase; }
.app-field > .app-input{ padding: 0 20px 10px; background: #fff; font-size: 14px; box-sizing: border-box; width: 100%; height: 31px; border: 0; border-bottom:1px solid #f2f2f2; -webkit-appearance:none; border-radius: 0;}
.app-field > .app-input:focus{border-color: blue; }
.app-field > .app-input.error{border-color: red;}

.app-fields > .app-field.field-type-button:last-child,
.app-fields > .app-field.field-type-submit:last-child{ margin-top: -2px;}

.app-field textarea.app-input{height:100px;resize:none}
.app-field .app-input-search{padding:10px 5px 10px 36px; height: 40px; margin: 0; background-repeat:no-repeat;background-position:10px center;-webkit-background-size:16px 16px;background-size:16px 16px;background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAACjElEQVR42r3Wz2sTQRjG8e2maW1rTYIoaPEgHqz/gD+KFi8VRb0pVPTi2UPNQRRCEHpQkCKIp0ilQg/BVqQKUi8q1lRzERQ0x3pQVKJIxTaxpql+DzkMD+xkNo1d+NBudt5535md3VnPdmQyGfP/FrSjC+trOhDxmnxo4k70I4lxzOA18riPYRzFFolvSvJBPMU8/los4w3S8JtRRCfGUZJEK3KuKiigV4pwHzl/43gYkLSKMhZRQsVsZ7T9jN1ShFPyCCahHVbwEiM4gyM4gRQe4Tu04DnskCLqFjACTf4Vg4gHxPo4gCfQ2HyY0e/BvIziIzY7LtgIpqC37YLrLIxJ8AK2SbBLIW+ln29ordfBdryXFZ2Eh7CLuBdVo4gyTmuhGjyA30byOexq5B2CKLIyC7fqBZ6V0U8jagkJ299jtHmWVXxJAsZq1xot4DD+GP3NYqOtgJQUkFllAQMoG/3l9WnSXS4p9yy7ys3suAzoOWK2gJMS8AKJBpP7uCj9PagXtBdFI+AH+mvXwhbQjbzslFfqPYYJ5OQ23IEPD2HeA6dkXyihz6WDFJblNTpkPN+BiWHO5E8ZyCvXytfhiwRXkZa2Qf0cRBG6F+yXWGsRhwL292nsRBei8NGKDmzCZePbQL1DPMw9TGJFR1IzgxtI4yqm8Av6LaDnOfSEmYlzWNKZCKJtAtrnEJcirNvqPnwK6tTyyfbM+F2vF9DmOhPmLfmABSzJVlvBIooYRQIeJiyFFlzXhJ73YQjXMYqbSOEYuqXtBtyTxLomtjrv8a7taswiJiy3bxYxr8mHLmYfWcua6JHQ5hcCP2BN3Eb7/8yva2LSSH4XsbVKbm5413AeCePamhYRRYv52z/AKK4/BRObOQAAAABJRU5ErkJggg==);}

/* Page & Content */
.app-page, .app-content{z-index: 2000;}
.app-content{z-index:2000;position:relative;overflow:auto; box-sizing: border-box; user-select: text; }
.app-list{list-style:none}

/* Nav */
.app-nav{ display: flex; list-style: none; position: relative;}
.app-nav > li, .app-nav > .app-button { width: 100%; padding: 0.75rem; transition: all 0.3s ease; display: flex; justify-content :center; align-items: center; }

/* Bottom Nav */
.app-bottom-nav{ position: fixed; bottom:0; left: 0; right:0; text-align:center; z-index: 5000}
.app-bottom-nav > .app-nav {height:  100%;}

/* Toast */
.app-toast{ position:absolute; bottom: -50px; left:0; right: 0; opacity: 0; padding: 20px; z-index: 5025; color: #fff; cursor: pointer;}
.app-toast.success{ background: #3C7; }
.app-toast.danger{ background: #DD4539;}
.app-toast.warning{ background: #EC1;}
.app-toast.info{ background: #4486F0;}

/* Dialog */
.app-dialog-container{position:absolute;z-index:5005;display: flex;justify-content: center; align-items: center; top:0;left:0;height:100%;width:100%;background:rgba(0,0,0,0);overflow:hidden;transition:background .425s cubic-bezier(0.4,0.6,0.05,1)}
.app-dialog-container.enabled{background:rgba(0,0,0,0.8)}
.app-dialog-container.enabled .app-dialog{transform:translate3d(0,0,0) !important;}
.app-dialog-container.closing.closing-success .app-dialog{}
.app-dialog{width:80%;max-width: 300px; transition:transform .425s cubic-bezier(0.4,0.6,0.05,1)}

/* Collapse */
.app-collapse .app-section {display: none;}
.app-collapse.open .app-section {display: block;}

/* App Swipes */
.app-swipes{ border-top: 1px solid #f2f2f2; background: linear-gradient(to right, #2B9 0%, #2B9 50%, #D45113 50%, #D45113 100%) !important; height: 40px; position: relative; }
.app-swipes:first-child{ border-top: 0;}
.app-swipes .app-section{ z-index: 50; margin: 0; padding: 0;}
.app-swipes.checked .app-section{ margin-left: 5px; color: #2B9; }
.app-swipes.unchecked .app-section{ margin-right: 5px; color: #D45113; }
.app-swipes:before, .app-swipes:after{ position: absolute; height: 40px; padding: 0 10px; line-height: 40px; box-sizing: border-box; width: 45%; top: 0; color: #fff; }
.app-swipes:before{ left: 0; content: '\2190'; text-align: left;}
.app-swipes:after{ right: 0; content: '\2192'; text-align: right; }

/* Loading */
.app-loader{ display: flex; align-items: center; justify-content: center; position: fixed; inset: 0; z-index: 5050; background: #000;}
.app-loader:after{ content: '';}
